import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { Card, CardBody, Col, Container, Row, Spinner } from 'reactstrap';
import { usePayments } from '../Purchases/usePayments';
import { useQueryClient } from '@tanstack/react-query';

const PaypalValidation = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { usePaypalValidate } = usePayments();
  const search = useLocation().search;
  const [paymentInfo, setPaymentInfo] = useState({ paymentId: '', payerId: '' })
  const { data: isValidated } = usePaypalValidate(paymentInfo);

  useEffect(() => {
    const urlParams = new URLSearchParams(search);
    const paymentId = urlParams.get('paymentId');
    const payerId = urlParams.get('PayerID');
    if (paymentId && payerId) {
      setPaymentInfo({ paymentId, payerId });
    } else {
      navigate('/');
    }
  }, []);

  useEffect(() => {
    if (isValidated) {
      queryClient.invalidateQueries({ queryKey: ['gauge-data'] });
      navigate('/');
    }
  }, [isValidated]);

  return (
    <div className="auth-page-wrapper pt-5 vh-100 d-flex align-items-center">
      <div className="auth-one-bg-position auth-one-bg" id="auth-particles" style={{height: '100%'}}>
        <div className="bg-overlay"></div>

        <div className="shape">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 1440 120"
          >
            <path d="M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8 1440,40L1440 140L0 140z"></path>
          </svg>
        </div>
      </div>

      <Container>
        <Row className="justify-content-center">
          <Col xs={12} lg={6} xl={5}>
            <Card>
              <CardBody>
                <div className="text-center mt-2">
                  <h5 className="text-primary">Validando pago...</h5>
                  <Spinner className="text-primary" />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )

};

export default PaypalValidation;
