import React from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import Paypal from "../../assets/images/payments/paypal.png";
import MercadoPago from "../../assets/images/payments/MercadoPago.svg";
import { useLoggedIn } from "../../Components/Hooks/useLoggedIn";
import { authProvider } from "../../Components/Hooks/authProvider";
import { useState } from "react";
import SpinnerCool from "../../Components/Common/Spinner/Spinner";
import StripePayment from './StripePayment';
import { usePayments } from './usePayments';

const ModalPayments = ({ isOpen, onToggle, e, currentMsisdn}) => {
  const { user } = useLoggedIn();
  const { phones } = authProvider();

  const { usePayMercadoPago, usePaypal } = usePayments();
  const { mutate: payMercadoPago, isPending: loadingMP, isSuccess: successMP } = usePayMercadoPago;
  const { mutate: payPaypal, isPending: loadingPaypal, isSuccess: successPaypal } = usePaypal;



  const [showStripeFields, setShowStripeFields] = useState(false);
  const [stripePaymentProcess, setStripePaymentProcess] = useState(false);


  const handleMercadoPagoPay = () => {
    payMercadoPago({ productId: e.id, msisdn: currentMsisdn });
  };

  const handlePaypalPay = () => {
    payPaypal({ productId: e.id, msisdn: currentMsisdn });
  };

  return (
    <div>
      <React.Fragment>
        <Modal
          isOpen={isOpen}
          toggle={() => {
            onToggle();
          }}
          centered
          className="mx-auto"
          style={{ maxWidth: "700px" }}
        >
          <ModalHeader className="modal-title d-flex justify-content-center">
            {/* <h5 className="text-primary">Medio de pago</h5> */}
          </ModalHeader>
          <ModalBody className="text-center p-2 mx-2">
            <Row className="justify-content-center d-flex align-items-center">
            <SpinnerCool  isLoading={stripePaymentProcess}/>
              <Col md={6}>
                <Row className="justify-content-center">
                  <Card className="text-center" style={{ width: "330px" }}>
                    <CardHeader
                      className=""
                      style={{ background: "#5c666e", color: "white" }}
                    >
                      {e.name}
                    </CardHeader>
                    <CardBody
                      style={{
                        background:
                          "linear-gradient(240deg, rgba(108,108,108,1) 0%, rgba(227,227,227,1) 50%)",
                      }}
                    >
                      <Row
                        style={{
                          fontSize: "2rem",
                          color: "rgb(49, 62, 72)",
                          fontStyle: "normal",
                          fontWeight: "600",
                        }}
                      >
                        <p>${e.amount}</p>
                      </Row>
                      <Row
                        dangerouslySetInnerHTML={{ __html: e.description }}
                      ></Row>
                    </CardBody>
                  </Card>
                </Row>
              </Col>

              <Col md={6}>
                <Row className="justify-content-center">
                  <Col xs={10}>
                    <Label className="text-primary">Número {currentMsisdn}</Label>
                  </Col>

                  <Col className="col-12 mt-4 text-primary">
                    Elige pagar con:
                  </Col>

                  {!showStripeFields && <>
                    <button className="mt-4 cursor-pointer"
                      type="button"
                      disabled={loadingPaypal || successPaypal}
                      style={{
                        background: "#ffc439",
                        width: "70%",
                        borderRadius: "4px",
                        border: "none",
                        height: "40px",
                      }}
                      onClick={handlePaypalPay}
                    >
                      { loadingPaypal ? <Spinner color="white" /> : <img src={Paypal} height="100%" /> }
                    </button>

                    <button className="mt-4 cursor-pointer"
                      type="button"
                      disabled={loadingMP || successMP}
                      style={{
                        background: "#a8e5ff",
                        width: "70%",
                        borderRadius: "4px",
                        border: "none",
                        height: "40px",
                        padding: "7px",
                      }}
                      onClick={handleMercadoPagoPay}
                    >
                      {loadingMP ? <Spinner color="primary" /> : <img src={MercadoPago} height="100%" /> }
                    </button>
                  </>}

                  <button className="mt-4 cursor-pointer"
                    type="button"
                    style={{
                      background: "#645aff",
                      width: "70%",
                      borderRadius: "4px",
                      border: "none",
                      height: "40px",
                      padding: "7px",
                      color: "white",
                    }}
                    onClick={() => setShowStripeFields(!showStripeFields)}
                  >
                    Pago con tarjeta
                  </button>

                  {showStripeFields && <StripePayment msisdn={currentMsisdn} productId={e.id} /> }
                </Row>
              </Col>
              <Col xs={6} sm={5}>
                <Button
                disabled={stripePaymentProcess}
                  color="primary"
                  outline
                  onClick={onToggle}
                  className="w-100 my-4"
                >
                  Cerrar
                </Button>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      </React.Fragment>
    </div>
  );
};

export default ModalPayments;
