import React from "react";

//import Scss
import "./assets/scss/themes.scss";

//imoprt Route
import Route from "./Routes";

// Fake Backend
import fakeBackend from "./helpers/AuthType/fakeBackend";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
fakeBackend();

function App() {
  const queryClient = new QueryClient({ defaultOptions: { queries: { staleTime: 60 * 1000 * 5 } } });
  return (
    <QueryClientProvider client={queryClient}>
      <Route />
    </QueryClientProvider>
  );
}

export default App;
