import { successAlert, errorAlert } from "../../helpers/alerts";
import { useState } from "react";
import { cancelSub, changePwd, getChildren, getHistory, getSubs, updateUserInfo } from '../../helpers/fakebackend_helper';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {saveUser} from "./useUser";

const authProvider = () => {
  const queryClient = useQueryClient();
  const [loadingUpdateUser, setLoadingUpdateUser] = useState(false);
  const [loadingChangePwd, setLoadingChangePwd] = useState(false);

  const { data: phones } = useQuery({
    queryKey: ['list-phones'],
    queryFn: getChildren,
    select: (response) => response.data
	});

  const useSubsQuery = (msisdn) => useQuery({
    queryKey: ['subs', msisdn],
    queryFn: () => getSubs(msisdn),
    select: (response) => response.data,
    enabled: !!msisdn,
  })

  const useCancelSubs = useMutation({
    mutationFn: ({ subId, msisdn }) => cancelSub({ subId, msisdn }),
    onSuccess: () => {
      successAlert("Eliminado", "Se ha cancelado la suscripción correctamente");
      queryClient.invalidateQueries({ queryKey: ['subs'] });
    },
    onError: () => errorAlert("Error", "No se ha podido cancelar la suscripción"),
  });

  const useHistoryQuery = (type = 'recharge', msisdn = '') => useQuery({
    queryKey: ['history', type, msisdn],
    queryFn: () => getHistory({ type, msisdn }),
    select: (response) => response?.data?.data,
  });

  const updateUser = async (userInfo) => {
    try {
      setLoadingUpdateUser(true);
      const response = await updateUserInfo(userInfo);
      setLoadingUpdateUser(false);

      if (response && response.status) {
        successAlert("Información actualizada", response.result);
        saveUser(response.data);
        window.location.reload();
      }
    } catch (error) {
      setLoadingUpdateUser(false);
      errorAlert("Error", "Ocurrio un error al actualizar la información");
      console.error(error);
    }
  };

  const changePassword = async (values) => {
    try {
      setLoadingChangePwd(true);
      const response = await changePwd(values);
      setLoadingChangePwd(false);

      if (response && response.status) {
        successAlert("Contraseña actualizada");
      }
    } catch (error) {
      setLoadingChangePwd(false);
      const errorMessage =
        error.response?.data?.message ||
        error.response?.data?.result?.description;
      errorAlert("Error", errorMessage);
    }
  };

  return {
    updateUser,
    changePassword,
    phones,
    useHistoryQuery,
    useSubsQuery,
    loadingUpdateUser,
    loadingChangePwd,
    useCancelSubs,
  };
};

export { authProvider };
