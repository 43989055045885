import { useMutation, useQuery } from '@tanstack/react-query';
import { addMsisdnToOrder, getCurrentOrder, updateOrderDetails } from '../../helpers/fakebackend_helper';

export const useOrders = () => {
  const orderDataQuery = useQuery({
    queryKey: ['current-order'],
    queryFn: async () => await getCurrentOrder(),
    select: (res) => res?.data,
  });

  const { mutateAsync: addNumber } = useMutation({
    mutationFn: async ({ msisdn }) => await addMsisdnToOrder({ msisdn }),
  });

  const { mutateAsync: addProduct } = useMutation({
    mutationFn: async ({ orderId, productId }) => await updateOrderDetails({
      orderid: orderId,
      productid: productId,
      action: 'add',
      qty: 1,
    }),
  });

  const { mutateAsync: clearProducts } = useMutation({
    mutationFn: async ({ orderId }) => await updateOrderDetails({
      orderid: orderId,
      action: 'clean',
    }),
  });

  const setupOrder = async ({ msisdn, productId, order, products }) => {
    if (!products || !order || !order.id) {
      throw new Error('Ocurrió un error al obtener el pedido');
    }

    const orderId = order.id;
    if (products?.length > 0) {
      await clearProducts({ orderId });
    }

    await addNumber({ msisdn });
    await addProduct({ productId, orderId });

    return { msisdn, productId, orderId };
  };

  return { setupOrder, orderDataQuery };
};
