import React, { useState } from "react";
import { Row, Col, Input, Spinner } from "reactstrap";
import AddAndroidDeviceModal from "./AddAndroidDeviceModal";
import { ApplicationsTable } from "./ApplicationsTable";
import { useNumbers } from '../../../../Components/Hooks/useNumbers';

const ApplicationsTab = () => {
  const [phone, setPhone] = useState('');
  const [iccid, setIccid] = useState('');
  const { addPhone, loading,addIccid, loadingiccid  } = useNumbers();
  const [isAndroidDeviceModalOpen, setIsAndroidDeviceModalOpen] =
    useState(false);

  return (
    <React.Fragment>
      <Row className="justify-content-center">
        <Col xs={12} sm={10}>
          <Row className="fs-1-1 text-primary px-2 mb-4">Agregar Teléfono</Row>
          <p>Importante</p>
          <ul>
            <li>
              El número a agregar recibirá un sms con la liga para aceptar
            </li>
            <li>
              El número tiene 2 horas apartir de que usted haga clic en el boton
              "Agregar" para que acepte la invitación
            </li>
            <li>
              El número aparecerá en su lista una vez que acepte la invitación
            </li>
            <li>Usted podrá interactuar como este numero</li>
            <li>
              Usted podrá generar facturas de este número pero los datos
              fiscales serán los de usted
            </li>
          </ul>

          <Row className="justify-content-center gap-3">
            <Col xs={12} sm={10}>
              <Input
                type="number"
                placeholder="Número a agregar"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </Col>

            <Col
              xs={12}
              sm={10}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <button
                disabled={loading}
                className="btn btn-primary"
                style={{ width: "50%" }}
                onClick={() => addPhone(phone)}
              >
                { !loading && 'Agregar' }
                { loading && <Spinner size="sm"></Spinner> }
              </button>
            </Col>
          </Row>
          <Row className="fs-1-1 text-primary px-2 my-4">Agregar un sim de internet o mifi</Row>
          <p>Importante</p>
          <ul>
            <li>
              El número no debe tener ya una relacion
            </li>
            <li>
              No se notificara al sim sobre esta acción
            </li>
            <li>
              Solo funciona con sims de internet o mifi
            </li>
            <li>
              El identificador es el iccid que viene en el registro del SIM
            </li>
          </ul>
          <Row className="justify-content-center gap-3">
            <Col xs={12} sm={10}>
              <Input
                type="text"
                placeholder="ICCID"
                value={iccid}
                onChange={(e) => setIccid(e.target.value)}
              />
            </Col>

            <Col
              xs={12}
              sm={10}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <button
                disabled={loading}
                className="btn btn-primary"
                style={{ width: "50%" }}
                onClick={() => addIccid(iccid)}
              >
                { !loadingiccid && 'Agregar' }
                { loadingiccid && <Spinner size="sm"></Spinner> }
              </button>
            </Col>
          </Row>

          {isAndroidDeviceModalOpen && (
            <AddAndroidDeviceModal
              isOpen={isAndroidDeviceModalOpen}
              onClose={() => setIsAndroidDeviceModalOpen(false)}
            />
          )}
          <Row className="fs-1-1 text-primary px-2 mt-5 mb-2">
            Lista de números
          </Row>
          <ApplicationsTable />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default ApplicationsTab;
