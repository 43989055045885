import { Route, useNavigate } from "react-router-dom";

import { useLoggedIn } from "../Components/Hooks/useLoggedIn";
import { useEffect } from 'react';

const AuthProtected = (props) => {
  const { isLoggedIn } = useLoggedIn();
  const navigate = useNavigate();

  /*
    Navigate is un-auth access protected routes via url
    */

  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/login');
    }
  }, [isLoggedIn, navigate]);

  if (!isLoggedIn) {
    return null;
  }

  return <>{props.children}</>;
};

const AccessRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          <>
            {" "}
            <Component {...props} />{" "}
          </>
        );
      }}
    />
  );
};

export { AuthProtected, AccessRoute };
