import React from "react";
import { Button, Modal, ModalBody, ModalHeader, Row, Col, Spinner } from "reactstrap";
import { useNumbers } from '../../../../Components/Hooks/useNumbers';

const DeleteDeviceModal = ({ isOpen, onToggle, onClose, device = {} }) => {
  const { removePhone, removingPhone } = useNumbers();

  const onConfirmDelete = () => {
    removePhone(device.msisdn, { onSuccess: () => onClose() });
  };

  return (
    <React.Fragment>
      <Modal
        isOpen={isOpen}
        toggle={() => {
          onToggle;
        }}
        centered
        className="mx-auto"
        style={{ maxWidth: "350px" }}
      >
        <ModalHeader className="modal-title d-flex justify-content-center">
          <span className="text-primary">Confirmación</span>

        </ModalHeader>
        <ModalBody className="text-center p-2 mx-2">
          <Row className="justify-content-center">
            <Col className="col-12">
              ¿Deseas eliminar el número {device.msisdn}?
            </Col>
            <Col xs={6} sm={5}>
              <Button
                color="primary"
                outline
                onClick={onClose}
                className="w-100 my-4"
              >
                Cancelar
              </Button>
            </Col>
            <Col xs={6} sm={7}>
              <Button
                disabled={removingPhone}
                color="primary"
                onClick={onConfirmDelete}
                className="w-100 my-4"
              >
                { removingPhone ? <Spinner size="sm" /> : 'Aceptar' }
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default DeleteDeviceModal;
