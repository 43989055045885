import { downloadInvoice, generateInvoice, getOfferingList, getPaymentMethods, getRegimen, getRegimes } from '../../helpers/fakebackend_helper';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

const ProductsProvider = () => {
  const queryClient = useQueryClient();

  const getOfferings = (msisdn) => useQuery({
    queryKey: ['offerings', msisdn],
    queryFn: async () => await getOfferingList({ msisdn }),
    select: (response) => response?.data,
    enabled: !!msisdn,
  });

  const paymentMethodsQuery = useQuery({
    queryKey: ['payment-methods'],
    queryFn: async () => await getPaymentMethods(),
    select: (response) => response?.data,
  });

  const getRegimesQuery = (filterByRegimeNum) => useQuery({
    queryKey: ['regimes', filterByRegimeNum],
    queryFn: async () => await getRegimes(filterByRegimeNum),
    select: (response) => response?.data,
  });

  const getRegimenQuery = (odoo_regimen_tipo) => {
    return useQuery({
      queryKey: ['regimen', odoo_regimen_tipo],
      queryFn: async () => await getRegimen(odoo_regimen_tipo),
      select: (response) => response?.data,
    });
  };
  const useGenerateInvoiceQuery = useMutation({
    mutationFn: async ({ orderId, method, usage }) => await generateInvoice({ order_id: orderId, mx_method_id: method, mx_usage: usage }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['history'] });
    },
  });

  const useDownloadNoteQuery = useMutation({
    mutationFn: async (orderId) => await downloadInvoice({ order_id: orderId }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['history'] });
    },
  });

  return {
    getOfferings,
    paymentMethodsQuery,
    getRegimesQuery,
    getRegimenQuery,
    useGenerateInvoiceQuery,
    useDownloadNoteQuery,
  };
};

export { ProductsProvider };
