import React from "react";
import { Button, Modal, ModalBody, ModalHeader, Row, Col, Spinner } from "reactstrap";
import { authProvider } from '../../../../Components/Hooks/authProvider';

const DeleteSubModal = ({ isOpen, onClose, sub, msisdn }) => {
  const { useCancelSubs } = authProvider();
  const { mutate: cancelSub, isPending: isCancelling } = useCancelSubs;

  const onCancel = () => {
    cancelSub({ subId: sub?.id, msisdn }, {
      onSuccess: () => onClose()
    });
  };

  return (
    <React.Fragment>
      <Modal
        isOpen={isOpen}
        centered
        className="mx-auto"
        style={{ maxWidth: "350px" }}
      >
        <ModalHeader className="modal-title d-flex justify-content-center">
          <h5 className="text-primary">Confirmación</h5>
        </ModalHeader>
        <ModalBody className="text-center p-2 mx-2">
          <Row className="justify-content-center">
            <Col className="col-12">
              ¿Deseas cancelar la suscripción {sub?.id}?
            </Col>
            <Col xs={6} sm={5}>
              <Button
                disabled={isCancelling}
                color="primary"
                outline
                onClick={onClose}
                className="w-100 my-4"
              >
                Cancelar
              </Button>
            </Col>
            <Col xs={6} sm={7}>
              <Button
                disabled={isCancelling}
                color="primary"
                onClick={onCancel}
                className="w-100 my-4"
              >
                { isCancelling ? <Spinner size="sm" /> : 'Aceptar'  }
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default DeleteSubModal;
