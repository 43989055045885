import { Formik } from 'formik';
import * as Yup from "yup";
import {
  Button,
  Col,
  Input,
  Label,
  Row,
  Spinner,
  Tooltip,
} from "reactstrap";
import { useState, useRef } from 'react';
import { usePayments } from './usePayments';
import { useNavigate } from 'react-router-dom';

const StripePayment = ({ productId, msisdn }) => {
  const { usePayStripe } = usePayments();
  const { mutate: payWithStripe, isPending: loading } = usePayStripe;
  const navigate = useNavigate();
  const currentYear = new Date().getFullYear() % 100;
  const cardNumberRef = useRef(null);
  const monthRef = useRef(null);
  const yearRef = useRef(null);
  const cvvRef = useRef(null);

  const initialValues = {
    fullName: '',
    cardNumber: '',
    month: '',
    year: '',
    cvv: '',
    productId,
    msisdn,
    recurrentPayment: false,
  };

  const validationSchema = Yup.object({
    fullName: Yup.string().required('El nombre es obligatorio'),
    cardNumber: Yup.string()
      .required('El número de tarjeta es obligatorio'),
    month: Yup.string()
      .matches(/^(0[1-9]|1[0-2])$/, 'El mes debe ser un número entre 01 y 12')
      .required('El mes es obligatorio'),
    year: Yup.string()
      .matches(/^[0-9]{2}$/, 'El año debe tener 2 dígitos')
      .test('year', 'El año debe ser mayor o igual al año actual', value => parseInt(value, 10) >= currentYear)
      .required('El año es obligatorio'),
    cvv: Yup.string()
      .matches(/^[0-9]{3,4}$/, 'El CVV debe tener 3 o 4 dígitos')
      .required('El CVV es obligatorio'),
    productId: Yup.string().required(),
    msisdn: Yup.string().required(),
    recurrentPayment: Yup.boolean().required(),
  });

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  const handleCardInput = (e, handleChange, nextField) => {
    const value = e.target.value.replace(/\s/g, '').replace(/(\d{4})/g, '$1 ').trim();
    handleChange({ target: { name: e.target.name, value } });
    if (value.length === 19 && nextField) {
      nextField.current.focus();
    }
  };

  const handleInputChange = (e, handleChange, maxLength, nextField) => {
    const value = e.target.value;
    handleChange(e);
    if (value.length === maxLength && nextField) {
      nextField.current.focus();
    }
  };

  const handleSubmit = async (values) => {
    const payload = { ...values, cardNumber: values.cardNumber.replace(/\s/g, '') };
    payWithStripe(payload, {
      onSuccess: () => navigate('/')
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {
        props => (
          <form onSubmit={props.handleSubmit}>
            <Row className="justify-content-center mt-4">
              <Col xs={10}>
                <Label className="text-primary">Nombre</Label>
                <Input
                  required
                  name="fullName"
                  placeholder="Nombre en la tarjeta"
                  className="form-control"
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  value={props.values.fullName || ''}
                />
              </Col>

              <Col xs={10} className="mt-2">
                <Label className="text-primary">Tarjeta</Label>
                <Input
                  innerRef={cardNumberRef}
                  name="cardNumber"
                  placeholder="**** **** **** ****"
                  type="text"
                  required
                  className="form-control"
                  onChange={(e) => handleCardInput(e, props.handleChange, monthRef)}
                  onBlur={props.handleBlur}
                  value={props.values.cardNumber || ''}
                  maxLength={19}
                />
              </Col>

              <Col xs={10} className="mt-2">
                <Label className="text-primary">Vigencia</Label>
                <div className="d-flex align-items-center gap-3">
                  <Input
                    innerRef={monthRef}
                    name="month"
                    placeholder="01"
                    type="text"
                    required
                    className="form-control"
                    onChange={(e) => handleInputChange(e, props.handleChange, 2, yearRef)}
                    onBlur={props.handleBlur}
                    value={props.values.month || ''}
                    maxLength={2}
                  />
                  /
                  <Input
                    innerRef={yearRef}
                    name="year"
                    placeholder="20"
                    type="text"
                    required
                    className="form-control"
                    onChange={(e) => handleInputChange(e, props.handleChange, 2, cvvRef)}
                    onBlur={props.handleBlur}
                    value={props.values.year || ''}
                    maxLength={2}
                  />
                </div>
              </Col>

              <Col xs={10} className="mt-2">
                <Label className="text-primary">CVV</Label>
                <Input
                  innerRef={cvvRef}
                  name="cvv"
                  placeholder="***"
                  type="password"
                  required
                  className="form-control"
                  onChange={(e) => handleInputChange(e, props.handleChange, 4)}
                  onBlur={props.handleBlur}
                  value={props.values.cvv || ''}
                  maxLength={4}
                />
              </Col>

              <Col xs={10} className="mt-2 gap-2 d-flex">
                <Label className="d-flex align-items-center" style={{ fontSize: "12px" }} onClick={e => e.stopPropagation()}>
                  <Input
                    style={{ marginRight: "8px" }}
                    name="recurrentPayment"
                    placeholder="***"
                    type="checkbox"
                    className="form-control"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    checked={props.values.recurrentPayment}
                  />
                  Aceptar cobro recurrente
                </Label>
                <i
                  className="fas fa-question-circle text-primary"
                  id="infoIcon"
                ></i>
                <Tooltip
                  placement="top"
                  isOpen={tooltipOpen}
                  target="infoIcon"
                  toggle={toggle}
                >
                  El cobro recurrente consiste en que al cabo del
                  periodo del paquete, Se hará un cobro por la misma
                  cantidad, dandote los beneficios del paquete
                  adquirido.
                  <br />
                  Este cobro se hará al termino de cada periodo hasta
                  que usted decida cancelarlo.
                </Tooltip>
              </Col>

              <Col xs={10}>
                <Button
                  color="primary"
                  type="submit"
                  className="w-100 my-4"
                  disabled={loading} // Disable button when loading
                >
                  { !loading && 'Pagar' }
                  { loading && <Spinner size={'sm'} /> }
                </Button>
              </Col>
            </Row>
          </form>
        )
      }
    </Formik>
  );
};

export default StripePayment;
