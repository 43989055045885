import { Col, Row, Input,FormGroup } from "reactstrap";

const UserInfo = ({ user, phones, setPhone }) => {
  return (
    <Row className="d-flex align-items-center">
      {/* <Col xs={4} className="d-flex align-items-center justify-content-center">
        <Row>
          <Col xs={12} className="d-flex justify-content-center">
            <i className="bx bx-dollar-circle fs-1 text-primary" />
          </Col>
          <Col xs={12} className="d-flex justify-content-center">
            Saldo
          </Col>
          <Col xs={12} className="d-flex justify-content-center">
            $1,000.00
          </Col>
        </Row>
      </Col> */}
      <Col xs={6} className="d-flex justify-content-center">
        <Row className="gy-2">
          <Col xs={12}>
            <Row>
              <Col
                xs={12}
                className="d-flex align-items-center justify-content-center"
              >
                <i className="bx bx-user fs-1 text-primary" />
              </Col>
              <Col
                xs={12}
                className="d-flex align-items-center justify-content-center"
              >
                Usuario
              </Col>
              <Col
                xs={12}
                className="d-flex align-items-center justify-content-center text-center"
              >
                {user.name}
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>

      <Col xs={6} className="d-flex justify-content-center">
        <Row>
          <Col
            xs={12}
            className="d-flex align-items-center justify-content-center"
          >
            <i className="bx bx-bookmark fs-1 text-primary" />
          </Col>

          <Col
            xs={12}
            className="d-flex align-items-center justify-content-center"
          >
            Número
          </Col>
          <Col
            xs={12}
            className="d-flex align-items-center justify-content-center"
          >
          <FormGroup>
          <Input type="select" onChange={(e) => setPhone(e.target.value)}>
              { user.msisdn && <option value={user.msisdn}>{user.name+' '+user.last_name+' ('+user.msisdn+')'}</option> }

              { phones && phones.map(phone =>
                phone.msisdn && <option key={phone.msisdn} value={phone.msisdn}>{phone.name+' '+phone.last_name+' ('+phone.msisdn+')'}</option>
              ) }
          </Input>
        </FormGroup>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default UserInfo;
