import React from "react";

import { Row, Col, Label,  Input,FormGroup } from "reactstrap";
import { authProvider } from "../../../../Components/Hooks/authProvider";
import { useLoggedIn } from "../../../../Components/Hooks/useLoggedIn";
import { useState } from "react";
import DataTable from "../DataTable";
import "./style.css";

const TimeAirTab = () => {
  const headers = [
    { text: "Nombre", value: "name" },
    { text: "Fecha", value: "date_order" },
    { text: "Total", value: "amount_total" },
  ];

  const { user } = useLoggedIn();
  const { phones, orderLines, useHistoryQuery } = authProvider();

  const [msisdn, setMsisdn] = useState(user.msisdn);
  const [type, setType] = useState('recharge');
  const { data: items, isLoading: loadingItems } = useHistoryQuery(type, msisdn);

  return (
    <React.Fragment>
      <Row className="mb-4">
        <Col xs={12}>
          <Row className="align-items-end my-2">
            <Col xs={12} sm={6} md={4}>
              <Label className="form-label text-primary">Número</Label>
              <FormGroup>
                <Input type="select" onChange={(e) => setMsisdn(e.target.value)}>
                <option value={user.msisdn}>Tú {user.name+' '+user.last_name+' ('+user.msisdn+')'}</option>
                      {phones && phones.map(phone => (
                        phone.msisdn && <option key={phone.msisdn} value={phone.msisdn}>{phone.name+' '+phone.last_name+' ('+phone.msisdn+')'}</option>
                      ))}
                </Input>
              </FormGroup>
            </Col>
            <Col xs={12} sm={6} md={4}>
              <Label className="form-label text-primary">Tipo</Label>
              <FormGroup>
              <Input
              type="select"
                className="form-select"
                value={type}
                onChange={(e) => setType(e.target.value)}
                defaultValue={type}
              >
                <option value="recharge">Recargas</option>
                <option value="pay" >Compras</option>
              </Input>
              </FormGroup>
            </Col>
          </Row>
        </Col>
      </Row>
      <Col>
        <DataTable
          headers={headers}
          orderLines={orderLines}
          phone={msisdn}
          items={items}
          loading={loadingItems}
        />
      </Col>
    </React.Fragment>
  );
};

export default TimeAirTab;
