import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
} from "reactstrap";
import { Link, useSearchParams, useNavigate } from "react-router-dom";
import withRouter from "../../Components/Common/withRouter";
import logoLight from "@images/logo-light.png";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import { errorAlert, successAlert } from "../../helpers/alerts";
import { activateEmail } from '../../helpers/fakebackend_helper';

const ActivateEmail = (props) => {
  document.title = "Validar email | Panda Móvil";
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  let url = searchParams.get('url')
    .replace(/_/g, '/')
    .replace(/@/g, '=')
    .replace(/\$/g, '&');


  useEffect(() => {
    if (!url) {
      errorAlert('No está autorizado para esta acción');
      navigate('/login');
      return;
    }

    const activate = async () => {

      try {
        console.log(url);
        let response = await activateEmail(url);

        if (response && response.status) {
          successAlert('Verificación exitosa', 'Ya puedes ingresar a tu cuenta');

          return true;
        }
        return false;
      } catch (err) {

        errorAlert("Error", '<p>Ocurrió un error al validar tu email<br/><br/>Inténtelo de nuevo o comuníquese con soporte</p>');
        return false;
      }
    };

    activate();
  }, [url, navigate]);

  return (
    <ParticlesAuth>
      <div className="auth-page-content">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="text-center mt-sm-5 mb-4 text-white-50">
                <div>
                  <Link to="/" className="d-inline-block auth-logo">
                    <img src={logoLight} alt="" height="40" />
                  </Link>
                </div>
              </div>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="mt-4">
                <CardBody className="p-4">
                  <div className="text-center mt-2">
                    <h5 className="text-primary">Validar email</h5>
                    <lord-icon
                      src="https://cdn.lordicon.com/rhvddzym.json"
                      trigger="loop"
                      colors="primary:#0ab39c"
                      className="avatar-xl"
                      style={{ width: "120px", height: "120px" }}
                    ></lord-icon>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </ParticlesAuth>
  );
};

ActivateEmail.propTypes = {
  history: PropTypes.object,
};

export default withRouter(ActivateEmail);
