import { Button, Card, CardBody, Spinner } from "reactstrap";
import { useState } from "react";
import { ProductsProvider } from "../../../Components/Hooks/ProductsProvider";
import ModalFactura from './ModalFactura';
import { useLoggedIn } from '../../../Components/Hooks/useLoggedIn';

const DataTable = ({
  headers = [],
  items = [],
  loading = false,
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [currentOrderId, setCurrentOrderId] = useState('');
  const [downloadingId, setDownloadingId] = useState(null);

  const { user: userInfo } = useLoggedIn();
  const { paymentMethodsQuery, getRegimesQuery, useDownloadNoteQuery } = ProductsProvider();
  const { data: paymentMethods } = paymentMethodsQuery;
  const { data: regimes } = getRegimesQuery(userInfo?.l10n_mx_edi_fiscal_regime);
  const { mutateAsync: downloadInvoice, isPending: downloading } = useDownloadNoteQuery;

  const getPDFFromBase64 = async (base64Data, fileName) => {
    try {
      const binaryData = atob(base64Data);
      const arrayBuffer = new ArrayBuffer(binaryData.length);
      const uint8Array = new Uint8Array(arrayBuffer);

      for (var i = 0; i < binaryData.length; i++) {
        uint8Array[i] = binaryData.charCodeAt(i);
      }

      const blob = new Blob([uint8Array], { type: 'application/pdf' });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      link.click();
    } catch (error) {
      console.log(error);
    }
  };

  const convertTime = (date) => new Date(date).toLocaleString();

  const handleOpenModal = (item) => {
    setCurrentOrderId(item?.rel_orders_item[0]?.order_id);
    setModalVisible(true);
  };

  const download = async (orderId) => {
    setDownloadingId(orderId);
    const response = await downloadInvoice(orderId);
    const responseData = response?.data;

    if (responseData?.pdfB64 && responseData.pdfName) {
      getPDFFromBase64(responseData.pdfB64, responseData.pdfName);
    }
  };

  return (
    <Card>
      <CardBody>
        <div className="table-responsive table-card">
          <table className="table align-middle table-borderless table-centered table-nowrap mb-0">
            <thead className="text-muted table-light">
              <tr>
                {headers.map((header) => (
                  <th key={header.value} scope="col">
                    {header.text}
                  </th>
                ))}
                <th style={{ textAlign: "center" }}>Acciones</th>
              </tr>
            </thead>
            <tbody>
              { !loading && items && items.length > 0 && items.map((item, index) =>
                <tr key={'history-'+index}>
                  <td>
                    { item?.rel_orders_item[0] && item.rel_orders_item[0].odoo_name }
                  </td>
                  <td>{item.paid_at}</td>
                  <td>${item.total}</td>
                  <td
                    align="center"
                    className="d-flex gap-5 justify-content-center"
                  >
                    { item?.rel_orders_item[0]?.order_id && item?.odoo_invoice_id &&
                      <Button
                        type="button"
                        style={{ width: "12rem" }}
                        onClick={() => download(item.rel_orders_item[0].order_id)}
                        disabled={item.rel_orders_item[0].order_id === downloadingId && downloading}
                      >
                        { (item.rel_orders_item[0].order_id === downloadingId && downloading) ? <Spinner size={"sm"} /> : 'Descargar Factura' }
                      </Button>
                    }
                    { item?.rel_orders_item[0]?.order_id && !item?.odoo_invoice_id &&
                      <Button
                        type="button"
                        style={{ width: "12rem" }}
                        onClick={() => handleOpenModal(item)}
                      >
                        Solicitar Factura
                      </Button>
                    }
                  </td>
                </tr>
              )}
              { !loading && (!items || (items && items.length === 0)) &&
                <tr>
                  <td colSpan="100%" className="text-center">
                    No hay registros existentes
                  </td>
                </tr>
              }
              { loading &&
                <tr>
                  <td colSpan="100%" className="text-center">
                    <Spinner />
                  </td>
                </tr>
              }
            </tbody>
          </table>
        </div>
        <ModalFactura
          orderId={currentOrderId}
          regimes={regimes}
          paymentMethods={paymentMethods}
          isOpen={modalVisible}
          onToggle={() => setModalVisible(false)}
        />
      </CardBody>
    </Card>
  );
};

export default DataTable;
