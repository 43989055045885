import { Card, CardBody, Spinner } from "reactstrap";

//import images
import { useLoggedIn } from "../../../Components/Hooks/useLoggedIn";

const ProfileCard = () => {
  const { user: userInfo, loadingUserInfo } = useLoggedIn();

  return (
    <Card className="">
      <CardBody className="p-4">
        <div className="text-center">
          { loadingUserInfo && <Spinner /> }
          { !loadingUserInfo && userInfo &&
            <>
              <h5 className="fs-17 mb-1">{userInfo?.name}</h5>
              <p className="text-muted mb-0">Numero: {userInfo?.msisdn}</p>
              <p className="text-muted mb-0">Email: {userInfo?.email}</p>
            </>
          }
        </div>
      </CardBody>
    </Card>
  );
};

export default ProfileCard;
