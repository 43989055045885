import { useMutation, useQueryClient } from '@tanstack/react-query';
import { errorAlert, successAlert } from '../../helpers/alerts';
import { addChildren, removeChild, updateAlias,addIccidP } from '../../helpers/fakebackend_helper';

const useNumbers = () => {
  const queryClient = useQueryClient();

  const { mutate: addPhone, isPending: loading } = useMutation({
    mutationFn: (msisdn) => addChildren({ msisdn, sms: false }),
    onSuccess: () => {
      successAlert("Invitación enviada correctamente");
      queryClient.invalidateQueries({ queryKey: ['list-phones'] });
    },
    onError: (err) => { errorAlert('Error', err) }
  });

  const { mutate: addIccid, isPending: loadingiccid } = useMutation({
    mutationFn: (iccid) => addIccidP({ iccid}),
    onSuccess: (res) => {
      successAlert(res.message);
      queryClient.invalidateQueries({ queryKey: ['list-phones'] });
    },
    onError: (err) => { errorAlert('Error', err) }
  });

  const { mutate: removePhone, isPending: removingPhone } = useMutation({
    mutationFn: (msisdn) => removeChild({ msisdn }),
    onSuccess: () => {
      successAlert('Eliminado', 'Se ha quitado de tu lista correctamente');
      queryClient.invalidateQueries({ queryKey: ['list-phones'] });
    },
    onError: () => { errorAlert('Error', 'No fue posible quitar este número') }
  });

  const { mutate: editAlias, isPending: editingAlias } = useMutation({
    mutationFn: ({ msisdn, name,last_name }) => updateAlias({ msisdn, name,last_name }),
    onSuccess: () => {
      successAlert('Se cambió el nombre correctamente');
      queryClient.invalidateQueries({ queryKey: ['list-phones'] });
    },
    onError: () => { errorAlert('Error', 'No fue posible cambiar el nombre') }
  });

  return { addPhone, removePhone, editAlias, loading, removingPhone, editingAlias,addIccid, loadingiccid };
};

export { useNumbers };
