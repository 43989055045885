import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { initMercadoPago, initPaypal, payStripe, validatePaypal } from '../../helpers/fakebackend_helper';
import { useOrders } from './useOrders';
import { errorAlert, successAlert } from '../../helpers/alerts';

export const usePayments = () => {
  const queryClient = useQueryClient();
  const { setupOrder, orderDataQuery } = useOrders();
  const { data: orderData } = orderDataQuery;

  const invalidate = () => {
    queryClient.invalidateQueries({ queryKey: ['history'] });
    queryClient.invalidateQueries({ queryKey: ['subs'] });
    queryClient.invalidateQueries({ queryKey: ['gauge-data'] });
  };

  const showPaymentError = (errorStatus,objectError) => {
    if (errorStatus==405) {
      errorAlert('Pago fallido', 'Este método de pago está deshabilitado');
      return;
    }
    errorAlert('Pago fallido', objectError.message);
  };

  // Stripe
  const usePayStripe = useMutation({
    mutationKey: 'pay-stripe',
    mutationFn: async ({ fullName, cardNumber, month, year, cvv, productId, msisdn, recurrentPayment }) => {
      const { orderId } = await setupOrder({ msisdn, productId, order: orderData?.order, products: orderData?.product });

      const paymentPayload = {
        orderid: orderId,
        cardName: fullName,
        cardNumber,
        month: month.toString().padStart(2, '0'),
        year: '20' + year,
        cvv,
        recurrentPayment,
      };

      return payStripe(paymentPayload);
    },
    onSuccess: () => {
      invalidate();
      successAlert('Pago exitoso', 'Se ha realizado el pago con exito');
    },
    onError: (error) => showPaymentError(error?.request?.status,error)
  });

  // Mercado Pago
  const usePayMercadoPago = useMutation({
    mutationKey: 'pay-mercado-pago',
    mutationFn: async ({ productId, msisdn }) => {
      const { orderId } = await setupOrder({ msisdn, productId, order: orderData?.order, products: orderData?.product });
      return initMercadoPago(orderId);
    },
    onSuccess: (response) => {
      if (response?.data?.url) {
        invalidate();
        window.location.replace(response.data.url);
      }
    },
    onError: (error) => showPaymentError(error?.request?.status)
  });

  // Paypal
  const usePaypal = useMutation({
    mutationKey: 'pay-paypal',
    mutationFn: async ({ productId, msisdn }) => {
      const { orderId } = await setupOrder({ msisdn, productId, order: orderData?.order, products: orderData?.product });
      return initPaypal(orderId);
    },
    onSuccess: (response) => {
      invalidate();
      if (response?.data?.response?.links) {
        const redirectLink = response.data.response.links.find(link => link.rel === 'approval_url');
        window.location.replace(redirectLink.href);
      }
    },
    onError: (error) => showPaymentError(error?.request?.status)
  });

  const usePaypalValidate = ({ paymentId, payerId }) => useQuery({
    queryKey: ['paypal-validate', paymentId, payerId],
    queryFn: () => validatePaypal({ paymentId, payerId }),
    enabled: !!paymentId && !!payerId,
    refetchInterval: (response) => response?.state?.data?.status ? false : 10000,
    select: (response) => response.status,
    onSuccess: () => {
      invalidate();
    },
  });

  return { usePayStripe, usePayMercadoPago, usePaypal, usePaypalValidate };
};
