import { Col, Row, Spinner } from "reactstrap";

import UserInfo from "./UserInfo";
import { authProvider } from "../../Components/Hooks/authProvider";
import { useState,useEffect } from "react";
import { useLoggedIn } from "../../Components/Hooks/useLoggedIn";
import Statistics from "./Statistics";

const Home = () => {
  const { phones } = authProvider();
  const { user, isLoggedIn } = useLoggedIn();
  const [phone, setPhone] = useState(user?.msisdn);


  useEffect(() => {
    if (user) {
      setPhone(user?.msisdn);
    }
  }, [user]);

  if (!isLoggedIn || user === null || user === undefined) {
    return null;
  }

  return (
    <div className="page-content px-5">
      <Row>
        <Col xs={12} lg={12} xl={12} className="pt-4 pt-lg-0">
          <div className="mt-3" style={{ marginBottom: 40 }}>
            <UserInfo phones={phones} user={user} setPhone={setPhone} />
          </div>

          <Statistics msidsn={phone} />
        </Col>
      </Row>
    </div>
  );
};

export default Home;
