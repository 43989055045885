import { USER_KEY } from '../../helpers/api_helper';
import { isLoggedOutSelector, setUser } from '../../slices/auth/login/reducer';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from "@tanstack/react-query";
import { getUserInfo } from "../../helpers/fakebackend_helper";
import { useEffect, useState } from 'react';

const useUser = () => {
  const dispatch = useDispatch();
  const isLoggedOut = useSelector(isLoggedOutSelector);
  const [initialUser, setInitialUser] = useState(getUser());
  const [isInitialUserLoaded, setIsInitialUserLoaded] = useState(false);

  useEffect(() => {
    const fetchInitialUser = async () => {
      const user = getUser();
      setIsInitialUserLoaded(true);
      if (user!=undefined) {
        setInitialUser(user);
        saveUser(user);
        dispatch(setUser(user));
      }
    };
    fetchInitialUser();
  }, [dispatch]);

  const { data: user, isLoading: loadingUserInfo } = useQuery({
    queryKey: ['user-info'],
    queryFn: async () => await getUserInfo(),
    enabled: !initialUser && !isLoggedOut,
    select: (res) => res?.data,
    initialData: initialUser,
  });

  useEffect(() => {
    if (user) {
      saveUser(user);
      dispatch(setUser(user));
    }else if(initialUser){
      saveUser(initialUser);
      dispatch(setUser(initialUser));
    }else if (!user && isInitialUserLoaded) {
      removeUser();
    }
  }, [user, dispatch, isInitialUserLoaded,initialUser]);

  return {
    user: user!=undefined ?user:initialUser ?? null,
    loadingUserInfo,
  };
};

export function saveUser(user) {
  localStorage.setItem(USER_KEY, JSON.stringify(user));
}

export function getUser() {
  const user = localStorage.getItem(USER_KEY);
  return user!=undefined ? JSON.parse(user) : undefined;
}

export function removeUser() {
  localStorage.removeItem(USER_KEY);
}

export default useUser;
