import PropTypes from "prop-types";
import React, { useEffect } from "react";
import {
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  Container,
  Spinner,
} from "reactstrap";

import { Link } from "react-router-dom";
import withRouter from "../../Components/Common/withRouter";

import logoLight from "@images/logo-light.png";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import { errorAlert, successAlert } from "../../helpers/alerts";
import { useState } from "react";
import { useLocation,useNavigate } from "react-router-dom";
import { acceptChildrenRequest } from '../../helpers/fakebackend_helper';

const AddChild = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [accepted, setAccepted] = useState(false);
  const [currentRef, setCurrentRef] = React.useState('')
  const search = useLocation().search;
  const navigate = useNavigate();

  useEffect(() => {
    const ref = new URLSearchParams(search).get('ref');
    if (ref) {
      setCurrentRef(ref)
    } else(
      navigate('/login')
    )
  }, []);

  const acceptRequest = async ()=> {
    setIsLoading(true);
    try {
      const response = await acceptChildrenRequest({ ref: currentRef });
      if (response) {
        successAlert('Correcto', 'La solicitud se ha aceptado correctamente')
        setAccepted(true);
      }
      setIsLoading(false);
    } catch (error) {
      errorAlert("Error", 'Ocurrió un error al obtener la informacion');
      setIsLoading(false);
    }
  };

  return (
    <ParticlesAuth>
      <div className="auth-page-content">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="text-center mt-sm-5 mb-4 text-white-50">
                <div>
                  <Link to="/" className="d-inline-block auth-logo">
                    <img src={logoLight} alt="" height="40" />
                  </Link>
                </div>
              </div>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="mt-4">

                <CardBody className="p-4">
                  <div className="text-center mt-2">
                    <h5 className="text-primary">Solicitud de administración</h5>
                  </div>
                  {!accepted
                    ? <>
                      <Alert
                        className="border-0 alert-warning text-center mb-2 mx-2"
                        role="alert"
                      >
                      Al hacer clic en &quot;Sí, acepto&quot;, estás aceptando:
                      </Alert>
                        <ul>
                          <li>No podrás actualizar tus datos, excepto la contraseña.</li>
                          <li>El administrador podrá ver y realizar acciones en tu cuenta.</li>
                          <li>Puedes realizar recargas, al igual que el administrador.</li>
                          <li>Podrás generar facturas, pero con los datos fiscales del administrador.</li>
                          <li>Para deshacer esta relación, contacta al administrador o llama al Callcenter.</li>
                        </ul>
                      <div className="text-center mt-4">
                      <button className="btn btn-success w-100" type="submit" onClick={acceptRequest} disabled={isLoading}>
                        { !isLoading && 'Si, acepto' }
                        { isLoading && <Spinner size="sm" className="me-2" /> }
                      </button>
                      </div>
                    </>
                    :
                    <Alert
                    className="border-0 alert-warning text-center mb-2 mx-2"
                    role="alert"
                  >
                    Ha aceptado la solicitud
                  </Alert>
              }
                </CardBody>
              </Card>

              <div className="mt-4 text-center">
                <p className="mb-0">
                  Ir a iniciar sesion{" "}
                  <Link
                    to="/login"
                    className="fw-semibold text-primary text-decoration-underline"
                  >
                    {" "}
                    Click here{" "}
                  </Link>{" "}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </ParticlesAuth>
  );
};

AddChild.propTypes = {
  history: PropTypes.object,
};

export default withRouter(AddChild);
