import React from "react";
import { Button, Modal, ModalBody, ModalHeader, Row, Col, Spinner, Input, Form, Label } from "reactstrap";
import { useNumbers } from '../../../../Components/Hooks/useNumbers';
import { Formik } from 'formik';
import * as Yup from "yup";

const UpdateAliasModal = ({ isOpen, onToggle, onClose, device = {} }) => {
  const { editAlias, editingAlias } = useNumbers();

  const validationSchema = Yup.object({
    msisdn: Yup.string().required(),
    name: Yup.string().required(),
    last_name: Yup.string().required(),
  });

  const update = (values) => {
    editAlias(values, { onSuccess: () => onClose() });
  };

  return (
    <React.Fragment>
      <Modal
        isOpen={isOpen}
        toggle={onToggle}
        centered
        className="mx-auto"
        style={{ maxWidth: "350px" }}
      >
        <ModalHeader className="modal-title d-flex justify-content-center">
          <span className="text-secondary">Cambiar nombre</span>

        </ModalHeader>
        <ModalBody className="text-center p-2 mx-2">
          <Row className="justify-content-center">
            <Col className="col-12">
              <Formik enableReinitialize
                initialValues={{ msisdn: device?.msisdn, name: device?.name,last_name:device?.last_name }}
                validationSchema={validationSchema}
                onSubmit={update}
              >
                { ({ handleChange, handleBlur, handleSubmit, values }) => (
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col xs={12} className="text-start">
                      <Label  className="form-label">
                        Nombre
                      </Label>
                        <Input
                          required
                          name="name"
                          placeholder="Nombre"
                          className="form-control"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.name || ''}
                        />
                      </Col>
                      <Col xs={12} className="pt-4 text-start">
                      <Label className="text-start">
                        Apellido
                      </Label>
                        <Input
                          required
                          name="last_name"
                          placeholder="Apellido"
                          className="form-control"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.last_name || ''}
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={6} sm={5}>
                        <Button
                          color="secondary"
                          outline
                          onClick={onClose}
                          className="w-100 my-4"
                        >
                          Cancelar
                        </Button>
                      </Col>
                      <Col xs={6} sm={7}>
                        <Button
                          type="submit"
                          disabled={editingAlias}
                          color="secondary"
                          className="w-100 my-4"
                        >
                          { editingAlias ? <Spinner size="sm" /> : 'Cambiar' }
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default UpdateAliasModal;
