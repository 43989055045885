import { FormFeedback } from 'reactstrap'

export const FeedbackMessage = ({ form, propName }) => {
  return (!!form?.touched?.[propName] && !!form?.errors?.[propName] && (
    <FormFeedback type="invalid">
      {form?.errors?.[propName]}
    </FormFeedback>
  ));

}
