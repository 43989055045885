import { useCallback, useEffect, useMemo } from "react";
import { TOKEN_KEY } from "../../helpers/api_helper";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getIsAliveToken, logoutUser } from "../../helpers/fakebackend_helper";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { isLoggedOutSelector, logoutUserSuccess } from '../../slices/auth/login/reducer';
import useUser, { removeUser } from './useUser';

const useLoggedIn = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const userToken = sessionStorage.getItem(TOKEN_KEY);
  const dispatch = useDispatch();
  const isLoggedOut = useSelector(isLoggedOutSelector);
  const { user, loadingUserInfo } = useUser();

  const { isSuccess: isTokenAlive, isFetching: loading } = useQuery({
    queryKey: ['user-token'],
    queryFn: async () => await getIsAliveToken(),
    enabled: !!userToken,
    refetchInterval: 300000,
    refetchIntervalInBackground: true
  });

  const logout = useCallback(async () => {
    dispatch(logoutUserSuccess())
    sessionStorage.removeItem(TOKEN_KEY);
    removeUser();
    navigate("/login");
    await logoutUser();
    queryClient.removeQueries();
  }, [dispatch, navigate, queryClient]);

  const isLoggedIn = useMemo(() => {
    return !isLoggedOut;
  }, [isLoggedOut]);

  useEffect(() => {
    if (!loading && !isTokenAlive) {
      logout();
    }
  }, [isTokenAlive, loading, logout]);

  return { loading, userToken, isLoggedIn, logout, user, loadingUserInfo };
};

export { useLoggedIn };
