import { Navigate } from "react-router-dom";

//login
import Login from "../Pages/Authentication/Login";
import ForgetPasswordPage from "../Pages/Authentication/ForgetPassword";
import SetNewPassword from "../Pages/Authentication/SetNewPassword";
import ActivateEmail from "../Pages/Authentication/ActivateEmail";
import ActivateAccount from "../Pages/Authentication/ActivateAccount";
import AddChild from "../Pages/Authentication/AddChild";
import PaypalValidation from "../Pages/PaypalValidation";
import Logout from "../Pages/Authentication/Logout";
import Register from "../Pages/Authentication/Register";

//Panda
import Home from "../Pages/Home";
import Profile from "../Pages/Profile";
import Sales from "../Pages/Sales/Sales.jsx";
import Balances from "../Pages/Balances";
//SIMS
import SimsInventory from "../Pages/SimsInventory";
import UserSims from "../Pages/SimsInventory/UserSims";
//Products
import ProductsInventory from "../Pages/ProductsInventory";
import UserProducts from "../Pages/ProductsInventory/UserProducts";
import UserTransfers from "../Pages/ProductsInventory/UserTransfers";
//Marketplace
import Marketplace from "../Pages/Marketplace";
import ShoppingCart from "../Pages/Marketplace/ShoppingCart";
import Portability from "../Pages/Portability";
import Activation from "../Pages/Activation";
import Purchases from "../Pages/Purchases";
import Users from "../Pages/Users";
import Reports from "../Pages/Reports";
import AdvertisingAndManuals from "../Pages/AdvertisingAndManuals";
import QueryUF from "../Pages/Query/QueryUF";
import PreRegister from "../Pages/PreRegister/PreRegister";
import Recharge from "../Pages/Recharge/Recharge.jsx";

const authProtectedRoutes = [
  { path: "/", component: <Home /> },
  { path: "/publicidad-y-manuales", component: <AdvertisingAndManuals /> },
  { path: "/usuarios", component: <Users /> },
  { path: "/reportes/:tab?/:subtab?", component: <Reports /> },
  { path: "/perfil", component: <Profile /> },
  { path: "/recargas", component: <Purchases /> },
  { path: "/activacion", component: <Activation /> },
  { path: "/portabilidad", component: <Portability /> },
  { path: "/marketplace/carrito", component: <ShoppingCart /> },
  { path: "/marketplace/:tab?", component: <Marketplace /> },
  {
    path: "/inventario-de-productos/transpaso/:username",
    component: <UserTransfers />,
  },
  {
    path: "/inventario-de-productos/inventario/:username",
    component: <UserProducts />,
  },
  { path: "/inventario-de-productos", component: <ProductsInventory /> },
  { path: "/inventario-de-sims/sims/:username", component: <UserSims /> },
  { path: "/inventario-de-sims", component: <SimsInventory /> },
  { path: "/saldos", component: <Balances /> },
  { path: "/ventas", component: <Sales /> },

  { path: "/consulta-uf", component: <QueryUF /> },
  { path: "/pre-registro", component: <PreRegister /> },
  { path: "/recarga", component: <Recharge /> },
  { path: "/validate", component: <PaypalValidation /> },
  { path: "/cancel", component: <Navigate to="/" /> },

  {
    path: "/",
    exact: true,

    // this route should be at the end of all other routes
    // eslint-disable-next-line react/display-name
    component: <Navigate to="/" />,
  },
  { path: "*", component: <Navigate to="/" /> },
];

const publicRoutes = [
  // Authentication Page
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPasswordPage /> },
  { path: "/new-password", component: <SetNewPassword /> },
  { path: "/activate-email", component: <ActivateEmail /> },
  { path: "/activate", component: <ActivateAccount /> },
  { path: "/add", component: <AddChild /> },
  { path: "/register", component: <Register /> },
];

export { authProtectedRoutes, publicRoutes };
