import { useState } from "react";
import { Card, CardBody } from "reactstrap";
import { authProvider } from "../../../../Components/Hooks/authProvider";
import DeleteDeviceModal from "./DeleteDeviceModal";
import UpdateAliasModal from './UpdateAliasModal';

export const ApplicationsTable = () => {
  const { phones } = authProvider();

  const [device, setDevice] = useState(null);
  const [isOpenDeleteDeviceModal, setIsOpenDeleteDeviceModal] = useState(false);
  const [isOpenUpdateAliasModal, setIsOpenUpdateAliasModal] = useState(false);
  const onUpdateDevice = (device) => {
    setDevice(device);
    setIsOpenUpdateAliasModal(true);
  };
  const onDeleteDevice = (device) => {
    setDevice(device);
    setIsOpenDeleteDeviceModal(true);
  };

  return (
    <Card>
      <CardBody>
        <div className="table-responsive table-card">
          <table className="table align-middle table-borderless table-centered table-nowrap mb-0">
            <thead className="text-muted table-light">
              <tr>
                <th scope="col">Telefono</th>
                <th scope="col" className="text-center">
                  Acciones
                </th>
              </tr>
            </thead>
            <tbody>
              { phones && phones.length ? (
                phones.map((item, index) => (
                  <tr key={index}>
                    <td>{item.name+' '+item.last_name+' ('+item.msisdn+')'}</td>
                    <td align="center">
                      <button type="button"
                        className="btn btn-secondary mx-1"
                        onClick={() => onUpdateDevice(item)}
                      >
                        Cambiar nombre
                      </button>
                      <button type="button"
                        className="btn btn-primary"
                        onClick={() => onDeleteDevice(item)}
                      >
                        Quitar de mi lista
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="100%" className="text-center">
                    No hay registros existentes
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {isOpenDeleteDeviceModal && device && (
            <DeleteDeviceModal
              isOpen={isOpenDeleteDeviceModal}
              device={device}
              onClose={() => setIsOpenDeleteDeviceModal(false)}
            />
          )}
          {isOpenUpdateAliasModal && device && (
            <UpdateAliasModal
              isOpen={isOpenUpdateAliasModal}
              device={device}
              onClose={() => setIsOpenUpdateAliasModal(false)}
            />
          )}
        </div>
      </CardBody>
    </Card>
  );
};
