import { useQuery } from '@tanstack/react-query';
import { getProfileData } from '../../helpers/fakebackend_helper';

const useGetStatus = (msisdn) => {
	const getParsedGaugeData = (gaugeData) => {
		let colors = [
      "#5c666e",
      "#ec3144",
      "#b62334",
      "#851824",
      "#3a4650",
      "#e7e6e6",
		];

		if (gaugeData?.data?.datasets?.[0] && gaugeData?.data?.labels?.length) {
      const info = gaugeData.data.datasets[0];
      colors = info?.backgroundColor ?? colors;

      if (info?.data?.length) {
        return gaugeData.data.labels.map((label, index) => {
          const amount = parseFloat(info.data[index]);
          return {
            index,
            name:  `${label}\n${amount} Gb`,
            amount,
            active: true,
            key: '',
            fill: colors[index],
          };
        });
      }
		}

		return [];
  }

  const parseDetails = (productsInfo,national=true) => {
    const products = Object.keys(productsInfo).map(productKey => ({
      ...productsInfo[productKey], key: productKey
    }));

    return products.map((product) => {
      const startsAt = new Date(product.start);
      const endsAt = new Date(product.end);
      const title = product.name || '';

      let smsLineParts = getLineParts(product.sms,national?'SMS Nacional':'SMS Roaming USA Canadá',national);
      let minLineParts = getLineParts(product.min,national?'Minutos Nacional':'Minutos Roaming USA Canadá',national);
      let rsLineParts = getLineParts(product.data,'Redes Sociales',national);

      const smsTotal = getTotal(product.sms);
      const minTotal = getTotal(product.min);
      let rsTotal = {total:0,used:0};

      let isMinUnlimit=false;
      let isSmsUnlimit=false;
      let isRSUnlimit=false;

      let smsMsg='';
      let minMsg='';
      let rsMsg='';

      let hideLineSms=false;
      let hideLineMin=false;
      let hideLineRs=false;

      smsLineParts.forEach(element => {
        if(element.total===0 && element.unused===0){
          hideLineSms=true;
          return;
        }
      });

      minLineParts.forEach(element => {
        if(element.total===0 && element.unused===0){
          hideLineMin=true;
          return;
        }
      });

      rsLineParts.forEach(element => {
        if(element.total===0 && element.unused===0){
          hideLineRs=true;
          return;
        }
      });

      if(national){
        isMinUnlimit="Minutos Nacional" in product.min && product.min["Minutos Nacional"].unlimit;
        isSmsUnlimit="SMS Nacional" in product.sms && product.sms["SMS Nacional"].unlimit;
      }else{
        isMinUnlimit="Minutos Roaming USA Canadá" in product.min && product.min["Minutos Roaming USA Canadá"].unlimit;
        isSmsUnlimit="SMS Roaming USA Canadá" in product.min && product.min["SMS Roaming USA Canadá"].unlimit;
      }
      isRSUnlimit="Redes Sociales" in product.data &&product.data["Redes Sociales"].unlimit;
      rsTotal="Redes Sociales" in product.data && !product.data["Redes Sociales"].unlimit?{total:product.data["Redes Sociales"].total,used:product.data["Redes Sociales"].used}:rsTotal;

      smsMsg  = isSmsUnlimit?'Mensajes Ilimitados':'';
      minMsg  = isMinUnlimit?'LLamadas Ilimitadas':'';
      rsMsg = isRSUnlimit?'Redes Sociales Ilimitadas':'';

      const lines = [
        {
          availableUnit: "mb",
          parts: rsLineParts,
          available: rsTotal.total - rsTotal.used,
          total: rsTotal.total,
          used: rsTotal.used,
          showLine:!isRSUnlimit && !hideLineRs,
          msg:rsMsg
        },
      {
        availableUnit: "sms",
        parts: smsLineParts,
        available: smsTotal.total - smsTotal.used,
        total: smsTotal.total,
        used: smsTotal.used,
        showLine:!isSmsUnlimit && !hideLineSms,
        msg:smsMsg
      },
      {
        availableUnit: "min",
        parts: minLineParts,
        available: minTotal.total - minTotal.used,
        total: minTotal.total,
        used: minTotal.used,
        showLine:!isMinUnlimit && !hideLineMin,
        msg:minMsg
      },

      ];
      return { title, startsAt, endsAt, lines };
    });
  }

  const createDetailLinePart = (detail, color) => {
    return (
      detail && {
      key: detail.name,
      title: detail.name,
      total: detail.total,
      unused: detail.unused,
      used: detail.used,
      color,
      }
    );
  }

  const getTotal = (detailInfo) => {
    const total = Object.keys(detailInfo).reduce((total, detailKey) => detailInfo[detailKey].total + total, 0);
    const used = Object.keys(detailInfo).reduce((used, detailKey) => detailInfo[detailKey].used + used, 0);
    return { total, used };
  }

  const getLineParts = (detailInfo, key,national=true) => {
    const details = Object.keys(detailInfo).map(detailKey => ({
      ...detailInfo[detailKey], name: detailKey
    }));
    if(national){
      const nationalDetail = details.find(
        (detail) => detail.name == key
      );
      const nationalPart = createDetailLinePart(nationalDetail, "#851823");
      if(nationalPart){
        return [nationalPart];
      }
      return[];
    }else{
      const roamingDetail = details.find(
        (detail) => detail.name == key
      );

      const roamingPart = createDetailLinePart(roamingDetail, "#b62334");
      if (roamingPart) {
        return [roamingPart];
      }
      return [];

    }

  }

	const useStatusQuery = (select) => useQuery({
		queryKey: ['gauge-data', msisdn],
		queryFn: () => getProfileData({ msisdn }),
    select: (response) => select(response.data),
		enabled: !!msisdn,
    retry: false,
	});

  const useGaugeDataNational = useStatusQuery((data) => getParsedGaugeData(data.gauge.national));
  const useGaugeDataInternational = useStatusQuery((data) => getParsedGaugeData(data.gauge.international));
  const useTotalInternational = useStatusQuery((data) => data.gauge?.international?.totalAvailableMb ?? '');
  const useTotalNational = useStatusQuery((data) => data.gauge?.national?.totalAvailableMb ?? '');
  const useIsMovilidad = useStatusQuery((data) => data.msisdn_type==='movilidad'?true:false);
  const useNationalCards = useStatusQuery((data)=>parseDetails(data.gauge?.national?.cards,true));
  const useInternationalCards = useStatusQuery((data)=>parseDetails(data.gauge?.international?.cards,false));
  return { useGaugeDataNational,useGaugeDataInternational, useTotalNational,useTotalInternational,useIsMovilidad,useNationalCards,useInternationalCards };
};

export default useGetStatus;
